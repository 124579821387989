<template>
  <div v-if="!loading">
    <v-form
      @submit.stop.prevent="get_requests_by_page()"
      ref="form"
      v-model="valid"
      :lazy-validation="lazy"
    >
      <v-row v-if="filter" justify="center" class="mt-6">
        <v-text-field
          class="mx-3"
          dense
          outlined
          :label="$t('name') + '/' + $t('email')"
          v-model="name_filter"
          style="max-width: 35%"
        ></v-text-field>
        <v-text-field
          class="mx-3"
          dense
          outlined
          :label="$tc('company', 1)"
          v-model="company_filter"
          style="max-width: 35%"
        ></v-text-field>
        <v-select
          class="mx-3"
          dense
          outlined
          :items="StatusFilterOptions"
          item-text="text"
          item-value="index"
          v-model="status_filter"
          :label="$t('status')"
          style="max-width: 15%"
        >
        </v-select>
        <v-btn class="mx-3 secondary" type="submit">{{ $t("apply") }}</v-btn>
      </v-row>
    </v-form>
    <v-simple-table>
      <thead>
        <tr>
          <th>
            {{ $t("name")
            }}<v-icon
              small
              :color="orderBy == 0 ? 'primary' : ''"
              class="ml-2"
              @click="header_click(0)"
              >{{
                orderByMode == 0 ? "mdi-arrow-up" : "mdi-arrow-down"
              }}</v-icon
            >
          </th>
          <th>
            {{ $t("email")
            }}<v-icon
              small
              :color="orderBy == 1 ? 'primary' : ''"
              class="ml-2"
              @click="header_click(1)"
              >{{
                orderByMode == 0 ? "mdi-arrow-up" : "mdi-arrow-down"
              }}</v-icon
            >
          </th>
          <th>
            {{ $tc("company", 1)
            }}<v-icon
              small
              :color="orderBy == 2 ? 'primary' : ''"
              class="ml-2"
              @click="header_click(2)"
              >{{
                orderByMode == 0 ? "mdi-arrow-up" : "mdi-arrow-down"
              }}</v-icon
            >
          </th>
          <th>
            {{ $t("date")
            }}<v-icon
              small
              :color="orderBy == 3 ? 'primary' : ''"
              class="ml-2"
              @click="header_click(3)"
              >{{
                orderByMode == 0 ? "mdi-arrow-up" : "mdi-arrow-down"
              }}</v-icon
            >
          </th>
          <th class="text-center">{{ $t("change_user_preference") }}</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(req, index) in requests" :key="index">
          <td>
            <v-icon color="secondary" v-if="req.User.IsAlpha">mdi-alpha</v-icon
            >{{ show_name(req) }}
          </td>
          <td>{{ req.User.Email }}</td>
          <td>{{ req.Company.Name }}</td>
          <td>{{ req.Timestamp.split("T")[0] }}</td>
          <td class="text-center">
            <v-btn
              v-if="!req.Changed"
              class="px-2 ml-1 secondary"
              @click="change_preference(req)"
              min-width="0"
              small
            >
              <v-icon small>mdi-check</v-icon>
            </v-btn>
            <span v-else>
              {{ $t("preference_change_successful") }}
            </span>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-pagination
      color="secondary"
      @input="changePage"
      :length="total_pages"
      v-model="page"
    >
    </v-pagination>
  </div>
  <v-container v-else>
    <v-progress-circular
      style="margin-left: 50%"
      indeterminate
      size="70"
      color="var(--primary)"
    ></v-progress-circular>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";

export default {
  name: "UserPreferenceChangeRequests",

  components: {},

  data: () => ({
    apiService: new ApiService(),
    loading: false,
    filter: true,
    customMessage: null,
    tab: 0,
    page: 1,
    total_pages: 1,
    name_filter: "",
    company_filter: "",
    status_filter: -1,
    orderBy: -1,
    orderByMode: 0,
    valid: true,
    lazy: false,
    request: null,
    response: null,
    delete_dialog: false,
    requests: [],
  }),
  async created() {
    this.get_requests_by_page();
  },
  computed: {
    StatusFilterOptions() {
      return [
        { text: this.$t("no_preference"), index: -1 },
        { text: this.$tc("pending", 1), index: 0 },
        { text: this.$tc("confirmed", 1), index: 1 },
      ];
    },
  },
  methods: {
    header_click(col) {
      this.orderBy = col;
      if (this.orderByMode == 0) {
        this.orderByMode = 1;
      } else {
        this.orderByMode = 0;
      }
      this.get_requests_by_page();
    },
    get_requests_by_page() {
      this.loading = true;
      var page = this.page;
      if (this.$route.query.page) {
        page = parseInt(this.$route.query.page);
      }
      var params = { Page: page };
      if (this.name_filter) {
        params.NameFilter = this.name_filter;
      }
      if (this.company_filter) {
        params.CompanyFilter = this.company_filter;
      }
      params.Type = this.status_filter;
      if (this.orderBy != null) {
        params.Order = this.orderBy;
        params.OrderMode = this.orderByMode;
      }
      this.apiService
        .postRequest("userpreferencechange/list/filter", params)
        .then((resp) => {
          var json = JSON.parse(resp);
          this.page = json.Pager.CurrentPage;
          this.total_pages = json.Pager.TotalPages;
          this.requests = json.Requests;

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    change_preference(req) {
      this.apiService
        .postRequest("userpreferencechange/change-preference", req)
        .then((resp) => {
          req.Changed = true;
        })
        .catch((error) => {});
    },
    changePage() {
      this.$router.push({
        path: "/requests",
        query: { page: this.page },
      });
      this.get_requests_by_page();
    },
    show_name: function (request) {
      if (request.User.Name === null) {
        return "";
      } else {
        return request.User.Name.concat(" ").concat(request.User.LastName);
      }
    },
  },
};
</script>
