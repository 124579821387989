<template>
  <div>
    <v-container id="comany-balance" fluid tag="section">
      <base-material-card
        icon="mdi-cash-multiple"
        inline
        color="secondary"
        class="px-5 py-3 mb-5"
      >
        <template v-slot:heading><v-icon>mdi-cash-multiple</v-icon></template>
        <template v-slot:after-heading
          ><h1 class="h5">
            <b>{{ $t("allocation_balance") }}</b>
          </h1></template
        >
        <div v-if="!loading">
          <v-row align="center">
            <v-col cols="12" md="4">
              <v-combobox
                v-model="selectedCompany"
                outlined
                hide-details
                dense
                :key="update"
                :items="companies"
                item-text="Name"
                item-value="CompanyId"
                :label="$tc('company', 1)"
              ></v-combobox>
            </v-col>
            <v-col>
              <v-select
                v-model="AllocationSummary.Currency"
                :items="currencyOptions"
                item-text="text"
                item-value="value"
                outlined
                hide-details
                dense
                :label="$t('currency')"
              ></v-select>
            </v-col>
            <v-col>
              <v-text-field
                v-model="AllocationSummary.InitialDate"
                dense
                :key="update"
                hide-details
                :label="$tc('range_date', 1)"
                type="date"
                outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="AllocationSummary.FinalDate"
                dense
                :key="update"
                hide-details
                :label="$tc('range_date', 2)"
                type="date"
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              ><v-btn
                :disabled="!!selectedCompany ? false : true"
                small
                class="dxa-btn-secondary"
                @click="getCompanyBalance"
                >{{ $t("apply") }}</v-btn
              >
              <v-btn
                :disabled="!!selectedCompany ? false : true"
                @click="cleanFilters"
                class="dxa-btn-secondary"
                small
                >{{ $t("clean") }}</v-btn
              >
            </v-col>

            <v-col
              cols="12"
              style="justify-content: space-between"
              class="d-flex"
            >
              <div class="value-box-flex">
                <p class="h6 text-center">{{ $tc("confirmed", 1) }}:</p>
                <div class="value-box-items">
                  <span
                    class="h4"
                    v-for="(item, i) in confirmedSummary"
                    :key="i"
                  >
                    {{ formatCurrency(item.currency, item.value) }}
                  </span>
                </div>
              </div>
              <div class="value-box-flex">
                <p class="h6 text-center">{{ $tc("pending", 1) }}:</p>
                <div class="value-box-items">
                  <span class="h4" v-for="(item, i) in pendingSummary" :key="i">
                    {{ formatCurrency(item.currency, item.value) }}
                  </span>
                </div>
              </div>
              <div class="value-box-flex">
                <p class="h6 text-center">{{ $t("total") }}:</p>
                <div class="value-box-items">
                  <span class="h4" v-for="(item, i) in totalSummary" :key="i">
                    {{ formatCurrency(item.currency, item.value) }}
                  </span>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
        <v-container v-else>
          <v-progress-circular
            style="margin-left: 50%"
            indeterminate
            size="70"
            color="primary"
          ></v-progress-circular>
        </v-container>
      </base-material-card>
    </v-container>
    <v-container id="interest-table" fluid tag="section">
      <base-material-card
        icon="mdi-cash-multiple"
        inline
        color="secondary"
        class="px-5 py-3 mb-5"
      >
        <template v-slot:heading><v-icon>mdi-cash-multiple</v-icon></template>
        <template v-slot:after-heading
          ><h1 class="h5">
            <b>{{ $t("requests") }}</b>
          </h1></template
        >
        <v-tabs v-model="tab">
          <v-tab>{{ $tc("company_interest", 2) }}</v-tab>
          <v-tab>{{ $t("user_preferences_change") }}</v-tab>
        </v-tabs>
        <AllocationRequestsTable v-show="tab == 0"></AllocationRequestsTable>
        <UserPreferenceChangeRequests
          v-show="tab == 1"
        ></UserPreferenceChangeRequests>
      </base-material-card>
    </v-container>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import { formatCurrency } from "@/shared/helpers/currencyHelper";
import { CurrencyEnum } from "@/shared/enums/CurrencyEnum";
import AllocationRequestsTable from "./tabs/UserInterest";
import UserPreferenceChangeRequests from "./tabs/UserPreferenceChange";

export default {
  name: "UserAllocationRequests",

  components: {
    AllocationRequestsTable,
    UserPreferenceChangeRequests,
  },

  data: () => ({
    gs: new GeneralServices(),
    apiService: new ApiService(),
    loading: false,
    companies: [],
    selectedCompany: null,
    tab: 0,
    update: 0,
    AllocationSummary: {
      CompanyId: null,
      InitialDate: null,
      FinalDate: null,
      Currency: null,
    },
    confirmedSummary: [],
    pendingSummary: [],
    totalSummary: [],
    formatCurrency: formatCurrency,
    CurrencyEnum: CurrencyEnum,
    currencyOptions: [
      {
        text: "R$",
        value: CurrencyEnum.BRL,
      },
      {
        text: "US$",
        value: CurrencyEnum.USD,
      },
    ],
  }),
  async created() {
    await this.getCompanies();
  },
  computed: {
    StatusOptions() {
      return [
        this.$tc("interest_status_pending", 1),
        this.$tc("interest_status_approved", 1),
        this.$tc("interest_status_declined", 1),
        this.$t("interest_status_waiting_investor"),
      ];
    },
  },
  methods: {
    getCompanies() {
      this.apiService.getRequest("company/list").then((response) => {
        this.companies = JSON.parse(response.message);
      });
    },
    async getCompanyBalance() {
      this.loading = true;
      this.AllocationSummary.CompanyId = !!this.selectedCompany
        ? this.selectedCompany.CompanyId
        : null;

      await this.apiService
        .postRequest(
          `companyinterest/company-balance/filter`,
          this.AllocationSummary
        )
        .then((resp) => {
          this.confirmedSummary = resp.content[0].totalRequestConfirmed;
          this.pendingSummary = resp.content[0].totalRequestPending;
          this.totalSummary = resp.content[0].total;
        })
        .catch((error) => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });
      this.loading = false;
    },
    cleanFilters() {
      this.AllocationSummary.InitialDate = null;
      this.AllocationSummary.FinalDate = null;
      this.selectedCompany = null;
      this.confirmedSummary = 0;
      this.pendingSummary = 0;
      this.totalSummary = 0;
      this.update++;
      this.AllocationSummary.Currency = null;
    },
  },
};
</script>

<style scoped>
.value-box-flex {
  display: flex;
  gap: 8px;
  align-items: flex-start;
}

.value-box-items > span {
  display: block;
  font-weight: bold;
}
</style>
