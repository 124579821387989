<template>
  <div class="alert-modal-bg">
    <div class="alert-modal-content">
      <font-awesome-icon
        icon="fa-solid fa-circle-xmark"
        class="alert-modal-icon"
      />
      <h1 class="alert-modal-title">
        {{ $t("not_approve_request_title") }}
      </h1>
      <p class="alert-modal-text">
        {{ $t("check_the_fields_below") }}
      </p>
      <ul class="alert-modal-text alert-modal-list-items">
        <li>{{ $t("not_approve_request_check_vehicle") }}</li>
        <li v-if="featureFlag">
          {{ $t("not_approve_request_check_adhesion_term") }}
        </li>
        <li v-if="featureFlag">
          {{ $t("not_approve_request_check_newsletter_subscription") }}
        </li>
        <li v-if="featureFlag">
          {{ $t("not_approve_request_check_stock_type_id") }}
        </li>
      </ul>
      <div class="alert-modal-btn-close">
        <NewDxaButton
          @btnFunction="closeDialog"
          :outline="true"
          :title="$t('close')"
        />
      </div>
    </div>
    <div>
      <font-awesome-icon
        @click="closeDialog"
        icon="fa-solid fa-xmark"
        class="alert-modal-icon-close"
      />
    </div>
  </div>
</template>

<script>
import NewDxaButton from "@/components/dxa/Button/NewDxaButton";

export default {
  name: "AlertApproveRequest",
  data: () => ({
    featureFlag: process.env.VUE_APP_CLICKSIGN === "ON",
  }),
  components: {
    NewDxaButton,
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
};
</script>

<style scoped>
ul,
li {
  padding: 0px;
  margin: 0px;
  list-style: none;
}

.alert-modal-bg {
  background: var(--white);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 12px;

  position: relative;
}

.alert-modal-content {
  display: grid;
  place-items: center;
  padding-top: 54px;
  padding-bottom: 30px;
}

.alert-modal-icon {
  color: #ff0000;
  width: 44px;
  height: 44px;
  margin-bottom: 10px;
}

.alert-modal-title {
  font-family: "Source Sans Pro";
  font-weight: 900;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 10px;
}

.alert-modal-text,
.alert-modal-text > li {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.alert-modal-list-items {
  display: grid;
  gap: 4px;
  margin-bottom: 40px;
}

.alert-modal-list-items li {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.alert-modal-list-items li::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 4px;
  background-color: var(--dark);
  border-radius: 50%;
}

.alert-modal-btn-close {
  max-width: 100px;
}

.alert-modal-icon-close {
  color: #afafaf;
  width: 16px;
  height: 16px;
  cursor: pointer;

  position: absolute;
  top: 14px;
  right: 14px;
}
</style>
